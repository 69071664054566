import { pt_BR } from 'ng-zorro-antd/i18n';
import pt from '@angular/common/locales/pt';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID, CUSTOM_ELEMENTS_SCHEMA, DEFAULT_CURRENCY_CODE } from '@angular/core';

import { SharedModule } from '@gen/shared';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { StateManagementService } from 'libs/shared/src/lib/state-management/state-management.service';

registerLocaleData(pt);

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, AppRoutingModule, SharedModule.forRoot(), BrowserAnimationsModule, HttpClientModule],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'pt',
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'BRL',
    },
    { provide: NZ_I18N, useValue: pt_BR },
    StateManagementService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
